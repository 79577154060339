<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-danger mb-10" role="alert">
            {{ message }}
          </div>
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <div class="mb-5">
              <label for="">Serial Number</label>
              <Field
                type="text"
                name="serial_number"
                class="form-control bg-transparent"
                v-model="formInput.serial_number"
              />
              <ErrorMessage
                name="serial_number"
                class="text-danger error-feedback"
              />
            </div>
            <!-- <div class="mb-5" v-if="$route.params.id">
              <label for="">Status</label>
              <Field
                type="text"
                name="status"
                class="form-control bg-transparent"
                v-model="formInput.status"
              />
              <ErrorMessage name="status" class="text-danger error-feedback" />
            </div> -->
            <div class="mb-5">
              <label for="">Keterangan</label>
              <Field
                as="textarea"
                type="text"
                name="note"
                class="form-control bg-transparent"
                v-model="formInput.note"
              />
              <ErrorMessage name="note" class="text-danger error-feedback" />
            </div>
            <div>
              <button class="btn btn-secondary" @click="$router.go(-1)">
                Back
              </button>
              <button
                :disabled="loading"
                type="submit"
                id="kt_sign_in_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress" v-show="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  >
                  </span>
                </span>
              </button>
            </div>
          </Form>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Service from "../../../services/base.service";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      serial_number: yup.string().required("Field is required!"),
      status: yup.string().nullable(),
      note: yup.string().nullable(),
    });

    return {
      loading: false,
      message: "",
      formInput: {
        serial_number: "",
        status: "",
        note: "",
      },
      schema,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    // this.getListData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("serial");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput = data;
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      this.loading = true;
      const BaseService = new Service("serial");
      try {
        const { message } = this.$route.params.id
          ? await BaseService.updateData(this.$route.params.id, data)
          : await BaseService.createData(data);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
